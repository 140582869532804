<template>
  <el-form
    ref="form"
    v-loading="contentLoading"
    :model="model"
    class="form"
    :label-width="$l('company.labelWidth','130px')">
    <el-form-item label="单位编号" prop="unitId" :rules="$rule.notNull">
      <el-input v-model="model.unitId"></el-input>
    </el-form-item>
    <el-form-item label="单位账号" prop="userId" :rules="$rule.notNull">
      <el-input v-model="model.userId"></el-input>
    </el-form-item>
    <el-form-item label="单位密码" prop="userPw" :rules="$rule.notNull">
      <el-input v-model="model.userPw"></el-input>
    </el-form-item>

    <el-form-item label="官网链接" prop="url">
      <el-input v-model="model.url"></el-input>
    </el-form-item>
    <el-button type="primary" @click="handleSubmit">保存</el-button>
  </el-form>
</template>


<script>
  import loginUtil from "@/util/loginUtil";

  export default {
    name: "Password",
    components: {},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        model: {
          unitId: "",
          userId: "",
          userPw: "",
          url: "",
          companyId: loginUtil.getCompanyCode(),
        },
      };
    },
    mounted() {
      this.getData(1);
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getData();
      },
      getData() {

        this.contentLoading = true;
        this.$http.get("/authentication/unit").then(data => {
          this.contentLoading = false;
          console.log(data,60);
          if (data) {
            this.model = data;
          }

        });

      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .save("/authentication/unit", this.model)
              .then(() => {
                this.submitLoading = false;
                // this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
    },
  };
</script>
<style scoped lang="scss">

</style>
